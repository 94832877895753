const cameraOffset = 0.4

export default [
    [
        -Math.cos(((0 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((0 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((1 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((1 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((2 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((2 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((3 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((3 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((4 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((4 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((5 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((5 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((6 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((6 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((7 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((7 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((8 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((8 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((9 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((9 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((10 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((10 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((11 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((11 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((12 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((12 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((13 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((13 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((14 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((14 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((15 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((15 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((16 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((16 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((17 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((17 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((18 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((18 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        -Math.cos(((19 + 5) * (Math.PI * 2)) / 20) * 5,
        0.5,
        Math.sin(((19 + 5) * (Math.PI * 2)) / 20) * 5 + cameraOffset,
    ],
    [
        Math.sin(((10 - 5) * (Math.PI * 2)) / 20) * 3.4,
        0.5,
        Math.cos(((10 - 5) * (Math.PI * 2)) / 20) * 3.4 + cameraOffset,
    ],
    [
        Math.sin(((10 - 5) * (Math.PI * 2)) / 20) * 1.7,
        0.5,
        Math.cos(((10 - 5) * (Math.PI * 2)) / 20) * 1.7 + cameraOffset,
    ],
    [
        0,
        0.5,
        0 + cameraOffset,
    ],
    [
        Math.sin(((0 - 5) * (Math.PI * 2)) / 20) * 1.7,
        0.5,
        Math.cos(((0 - 5) * (Math.PI * 2)) / 20) * 1.7 + cameraOffset,
    ],
    [
        Math.sin(((0 - 5) * (Math.PI * 2)) / 20) * 3.4,
        0.5,
        Math.cos(((0 - 5) * (Math.PI * 2)) / 20) * 3.4 + cameraOffset,
    ],
    [
        Math.sin(((15 - 5) * (Math.PI * 2)) / 20) * 3.4,
        0.5,
        Math.cos(((15 - 5) * (Math.PI * 2)) / 20) * 3.4 + cameraOffset,
    ],
    [
        Math.sin(((15 - 5) * (Math.PI * 2)) / 20) * 1.7,
        0.5,
        Math.cos(((15 - 5) * (Math.PI * 2)) / 20) * 1.7 + cameraOffset,
    ],
    [
        Math.sin(((5 - 5) * (Math.PI * 2)) / 20) * 1.7,
        0.5,
        Math.cos(((5 - 5) * (Math.PI * 2)) / 20) * 1.7 + cameraOffset,
    ],
    [
        Math.sin(((5 - 5) * (Math.PI * 2)) / 20) * 3.4,
        0.5,
        Math.cos(((5 - 5) * (Math.PI * 2)) / 20) * 3.4 + cameraOffset,
    ],
]