/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function TaurusConstellation(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('models/taurus-constellation-dhazele-2.glb')

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="BezierCurve001"
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve001.geometry}
          material={materials.Material}
          position={[0, 0, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('models/taurus-constellation-dhazele-2.glb')