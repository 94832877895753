/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import vertexShader from './vertexSparkling.glsl'
import fragmentShader from './fragmentSparkling.glsl'
import { useEffect, useMemo, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

export default function Constellation({ position, rotation, scale, modelPath, omitFactor=4 }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(modelPath)

  const count = nodes.star.geometry.attributes.position.count;
  const randomArray = new Float32Array(count / omitFactor);
  const positionArray = new Float32Array((count / omitFactor)*3);
  
  for (let i = 0; i < count/4; i++) {
    randomArray[i] = Math.random() * 2.0 * Math.PI;
    positionArray[i*3] = nodes.star.geometry.attributes.position.array[(i*(omitFactor * 3)*3)]
    positionArray[i*3+1] = nodes.star.geometry.attributes.position.array[(i*(omitFactor * 3)*3)+1]
    positionArray[i*3+2] = nodes.star.geometry.attributes.position.array[(i*(omitFactor * 3)*3)+2]
  }

  const { gl } = useThree();
  gl.setPixelRatio(Math.min(window.devicePixelRatio, 1))

  const uniforms = useMemo(() => ({
    uTime: { value: 0 },
  }), []);

  const shaderRef = useRef();
  useFrame((state, delta) => {
    if (shaderRef.current) {
      shaderRef.current.uniforms.uTime.value = state.clock.elapsedTime;
    }
  })

  return (
    <group ref={group} position={position} scale={scale} rotation={rotation} dispose={null}>
      <group name="Scene" scale={[1, 0.001, 1]} position={[0, -1, 0]} rotation={[Math.PI/2, 0, 0]}>
        <points>
          <bufferGeometry>
            <bufferAttribute
              attach="attributes-position"
              count={nodes.star.geometry.attributes.position.count / omitFactor / 3}
              array={positionArray}
              itemSize={3}
            />
            <bufferAttribute
              attach="attributes-aRandom"
              count={count / omitFactor}
              array={randomArray}
              itemSize={1}
            />
          </bufferGeometry>
          <shaderMaterial
            blending={THREE.AdditiveBlending}
            depthWrite={false}
            vertexColors={true}
            vertexShader={vertexShader}
            fragmentShader={fragmentShader}
            ref={shaderRef}
            uniforms={uniforms}
          />
        </points>
      </group>
    </group>
  )
}