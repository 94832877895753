import React from "react";
import { useGLTF } from "@react-three/drei";
import { useMemo } from "react";
import { SkeletonUtils } from "three-stdlib";
import { useGraph } from "@react-three/fiber";

export default function YootMesh({ 
  position, 
  rotation=[0, 0, 0], 
  scale=1,
  active=true,
}) {

  const { scene, materials } = useGLTF(
    "models/yoot-v2-regular.glb"
  );
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes } = useGraph(clone);

  // can't assign two materials to one mesh.
  // assign the other material to another mesh and export it
  const modelDisabled = useGLTF('models/yoot-v2-regular-disabled.glb')
  const sceneDisabled = modelDisabled.scene
  const materialDisabled = modelDisabled.materials
  const cloneDisabled = useMemo(() => SkeletonUtils.clone(sceneDisabled), [sceneDisabled]);
  const graphDisabled = useGraph(cloneDisabled);
  const nodesDisabled = graphDisabled.nodes

  return (
    <group position={position} rotation={rotation} scale={scale}>
      { active ? <mesh
          castShadow
          receiveShadow
          geometry={nodes.yoot1.geometry}
          material={materials['Material.005']}
          position={[0,0,0]}
          rotation={[Math.PI, 0, -1.539]}
        /> :
        <mesh
          castShadow
          receiveShadow
          geometry={nodesDisabled.yoot1.geometry}
          material={materialDisabled['material-disabled']}
          position={[0,0,0]}
          rotation={[Math.PI, 0, -1.539]}
        >
          {/* <meshStandardMaterial color='grey'/> */}
        </mesh>
      }
    </group>
  );
}

useGLTF.preload("models/yoot.glb")